<template>
	<div class="home">
		<!--轮播图-->
		<section class="banner" v-if="config.banner&&config.banner.isShow&&bannerList&&bannerList.length>0"
			v-lazy-container="{ selector: 'img' }">
			<Carousel arrow="always" dots="none" v-model="valueBanner" loop autoplay :autoplay-speed='3000'>
				<CarouselItem v-for="(item,index) in bannerList" :key="index">
					<div class="demo-carousel">
						<div class="bannerBox">
							<div class="xs-hide picCenter">
								<a :href="item.link" v-if="item.link" class="picCenter">
									<img :data-src="item.imagePcUrl" lazy="loading" class="lazyPic" />
								</a>
								<img :data-src="item.imagePcUrl" lazy="loading" class="lazyPic" v-else />
							</div>
							<div class="sm-hide picCenter">
								<a :href="item.link" v-if="item.link" class="picCenter" style="display: block;">
									<img :data-src="item.imageMobileUrl" lazy="loading" class="lazyPic" />
								</a>
								<img :data-src="item.imageMobileUrl" lazy="loading" class="lazyPic" v-else />
							</div>
						</div>
					</div>
				</CarouselItem>
			</Carousel>
		</section>
		<!--轮播图-->
		<!--搜索-->
		<section class="search wCenter" v-if="config.c1&&config.c1.isShow">
			<Form ref="formExhibition" :model="formExhibition" inline>
				<FormItem prop="industryCategoryId">
					<Select transfer v-model="formExhibition.industryCategoryId" placeholder="请选择行业分类" clearable>
						<Option :value="item.value" v-for="(item,index) in selectIndustryCategoryList" :key="index">
							{{item.label}}
						</Option>
					</Select>
				</FormItem>
				<FormItem prop="marketProfileId">
					<Select transfer v-model="formExhibition.marketProfileId" placeholder="请选择市场区域" clearable>
						<Option :value="item.value" v-for="(item,index) in selectMarketProfileList" :key="index">
							{{item.label}}
						</Option>
					</Select>
				</FormItem>
				<FormItem prop="yearMonth">
					<Select transfer v-model="formExhibition.yearMonth" placeholder="请选择展会时间" clearable>
						<Option :value="item.value" v-for="(item,index) in selectExhibitionTimeList" :key="index">
							{{item.label}}
						</Option>
					</Select>
				</FormItem>
				<FormItem>
					<Button type="primary" @click="handleSubmit">搜索</Button>
				</FormItem>
			</Form>
		</section>
		<!--搜索-->
		<!--近期展会-->
		<section class="exhibition wCenter" v-if="config.c2&&config.c2.isShow">
			<div class="homeTitle lr pt">
				近期展会
				<a class="moreBtn down" :href="downFile" v-if="isSubmit=='1'" target="_blank"><img
						src="@/assets/down.png" alt="">下载出展指南</a>
				<span class="moreBtn down block hand" v-else @click="showModal=true"><img src="@/assets/down.png"
						alt="">下载出展指南</span>
			</div>
			<div class="yaerMain">
				<div class="year">
					<div class="yearLeft canotClick" v-if="currentYearIndex==0">
						<Icon type="md-arrow-dropleft" color="#bbb" />
					</div>
					<div class="yearLeft" @click="handleYearPrev" v-else>
						<Icon type="md-arrow-dropleft" />
					</div>
					<div class="yearCenter">
						{{exhibitionTimeListLately.length>0&&exhibitionTimeListLately[currentYearIndex].label}}
					</div>
					<div class="yearLeft canotClick" v-if="currentYearIndex==exhibitionTimeListLately.length-1">
						<Icon type="md-arrow-dropright" color="#bbb" />
					</div>
					<div class="yearLeft" @click="handleYearNext" v-else>
						<Icon type="md-arrow-dropright" />
					</div>
				</div>
				<div class="monthPoint" v-if="monthLabel&&monthLabel.length>0">
					<div v-for="(item,index) in monthLabel" :key="index" :class="monthClass(item)"
						@click="handleChangeMonth(index)"></div>
				</div>
				<div class="line" v-if="monthLabel&&monthLabel.length>0"></div>
				<div class="month" v-if="monthLabel&&monthLabel.length>0">
					<div v-for="(item,index) in monthLabel" :key="index">{{ item.label }}</div>
				</div>
			</div>
			<div class="exhibitionMain" v-if="currMonthExhbtition&&currMonthExhbtition.length>0">
				<div class="exhibitionItemLeft picCenter">
					<img v-lazy="leftPic" :key="leftPic" class="lazyPic" lazy="loading" v-if="leftPic" />
					<img src="@/assets/noThumb_640_360.png" alt="" v-else>
				</div>
				<div class="exhibitionItemRight">
					<div class="exhibitionItemRightMain hand" v-for="(item,index) in currMonthExhbtition" :key="index"
						@mouseenter="handleChangePic(index)" @click="goDetailExhibition(item.id,1)">
						<div class="exhibitionItemDate">
							<p class="day">{{item.timeDay}}</p>
							<p class="month">{{item.timeMonth}}</p>
							<p class="year">{{item.timeYear}}</p>
						</div>
						<div class="exhibitionItemContent">
							<p class="title">{{item.nameCn}}</p>
							<div class="iconMain">
								<div class="iconItem" v-if='item.address'>
									<img src="../../assets/location.png" />
									<p>{{item.address}}</p>
								</div>
								<!-- <div class="iconItem" v-if="item.website">
                                <img src="../../assets/address.png" />
                                <p>
                                    <a :href="item.website">{{item.website}}</a>
                                </p>
                            </div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="moreExhibitionMain">
				<a class="moreExhibition" href="/exhibition" target="_blank">查看全部展会
					<Icon type="md-play" color='ff1932' size='20' />
				</a>
			</div>
		</section>
		<!--近期展会-->
		<!--新闻动态-->
		<section class="new" v-if="config.c3&&config.c3.isShow">
			<div class="wCenter">
				<div class="homeTitle">新闻动态</div>
				<div class="newMain">
					<div class="newMainRight" v-if="listNewsFirst">
						<div class="newMainRightR">
							<img v-lazy="listNewsFirst.coverImgUrl" :key="listNewsFirst.coverImgUrl" class="lazyPic"
								lazy="loading" />
							<p class="title sm-hide">{{listNewsFirst.title}}</p>
							<p class="content sm-hide" v-if="listNewsFirst.summary">{{listNewsFirst.summary}}</p>
							<p class="detail sm-hide" @click="goDetail(listNewsFirst.id)">查看详情 >></p>
						</div>
						<div class="newMainRightL xs-hide">
							<p class="title">{{listNewsFirst.title}}</p>
							<p class="content" v-if="listNewsFirst.summary">{{listNewsFirst.summary}}</p>
							<p class="detail hand" @click="goDetail(listNewsFirst.id)">查看详情 >></p>
						</div>
					</div>
					<div class="newMainLeft">
						<div class="newItem" v-for="(item,index) in listNews" :key="index" @click="goDetail(item.id)">
							<p class="title">{{item.title}}</p>
							<!-- <p class="content" v-if="item.summary">{{item.summary}}</p> -->
						</div>
						<div class="more">
							<a href="/news" target="_blank">查看更多</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--新闻动态-->
		<!--推荐展会-->
		<section class="reExhibition" v-if="config.c4&&config.c4.isShow">
			<div class="wCenter">
				<div class="homeTitle">推荐展会</div>
			</div>
			<div class="wCenterP partner">
				<div v-if="listExhibitonTop&&listExhibitonTop.length>0">
					<swiper :options="swiperOptionMobile" ref="mySwiper" v-if="isMobile"
						v-lazy-container="{selector: 'img' }">
						<swiper-slide v-for="(item,index) in listExhibitonTop" :key="index">
							<div class="picMain" @click="goDetailExhibition(item.id,0)">
								<div class="pic">
									<img :data-src="item.logoUrl" lazy="loading" width="100%" class="lazyPic" />
								</div>
								<p class="name">{{item.nameCn}}</p>
								<p class="time">{{item.startTime}}至{{item.endTime}}</p>
							</div>
						</swiper-slide>
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</swiper>
					<swiper :options="swiperOptionPc" ref="mySwiper" v-else v-lazy-container="{selector: 'img' }">
						<swiper-slide v-for="(item,index) in listExhibitonTop" :key="index">
							<div class="picMain" @click="goDetailExhibition(item.id,0)">
								<div class="pic">
									<img :data-src="item.logoUrl" lazy="loading" class="lazyPic" />
								</div>
								<p class="name">{{item.nameCn}}</p>
								<p class="time">{{item.startTime}}至{{item.endTime}}</p>
							</div>
						</swiper-slide>
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</swiper>
				</div>
			</div>
		</section>
		<!--推荐展会-->
		<!-- 弹框 -->
		<section v-show="showModal" class="formModal">
			<FormContact @cancel='showModal=false'></FormContact>
		</section>
	</div>
</template>

<script>
	import util from "@/libs/util.js";
	import {
		getPageConfigByCode,
		getListBanner,
		getIndustryCategoryList,
		getMarketProfileList,
		getExhibitionTimeList,
		getMonthList,
		getHomeLatelyList,
		getListNews,
		getHomeTopList,
		getFileUrlInHome,
		setHomeVisit,
		getExhibitionYearMonthList,
		getExhibitionYearList
	} from '@/api/api'
	import FormContact from '@/components/form-contact'
	import Cookies from 'js-cookie'
	export default {
		name: 'home',
		components: {
			FormContact
		},
		computed: {
			monthClass() {
				return item => {
					if (item.type == '1') {
						if (item.curMonth == '1') {
							return 'month_curr'
						} else {
							return 'month_have'
						}
					}
				}
			},
			isSubmit() {
				return Cookies.get('isSubmit')
			},
			isMobile() {
				return this.$store.state.app.isMobile;
			},

		},

		data() {
			return {
				// 手机端
				swiperOptionMobile: {
					slidesPerView: 2,
					spaceBetween: 30,
					// centeredSlides: true,
					autoplay: {
						delay: 2500,
					},
					// loop: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
				},
				// pc端
				swiperOptionPc: {
					slidesPerView: 4,
					spaceBetween: 30,
					// centeredSlides: true,
					autoplay: {
						delay: 2500,
					},
					//loop: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
				},

				config: {},
				bannerList: [],
				valueBanner: 0,
				// 当前年下标
				currentYearIndex: 0,
				// 行业分类
				selectIndustryCategoryList: null,
				// 市场区域
				selectMarketProfileList: null,
				// 展会时间
				selectExhibitionTimeList: null,
				formExhibition: {
					industryCategoryId: '',
					marketProfileId: '',
					yearMonth: '',
				},
				// 最近展会年份
				exhibitionTimeListLately: [],
				monthLabel: [],
				// 默认取后台返回的当前年份
				isDefaultYear: true,
				// 默认取后台返回的当前年份的第一个有数据的月份
				isDefaultMonth: true,
				// 当前月展会
				currMonthExhbtition: [],
				// 当前左侧图片
				leftPic: '',
				// 当前月下标
				currentMonthIndex: 0,
				// 新闻列表
				listNews: null,
				listNewsFirst: null,
				listExhibitonTop: null,
				showModal: false, //留资弹框
				downFile: '', //下载文件地址
				globalDefaultYear: '',
			}
		},
		mounted() {
			this.init()

		},
		methods: {
			init() {
				this.getPageConfigByCode()
				this.setHomeVisit()
				this.getFileUrlInHome()

			},
			// 页面配置
			getPageConfigByCode() {
				const params = {
					code: 'home/index',
				}
				getPageConfigByCode(params).then(res => {
					if (res.success) {
						this.config = JSON.parse(res.result)
						if (this.config.banner && this.config.banner.isShow) {
							this.getListBanner()
						}
						if (this.config.c1 && this.config.c1.isShow) {
							this.getIndustryCategoryList()
							this.getMarketProfileList()
							this.getExhibitionTimeList()
						}
						if (this.config.c2 && this.config.c2.isShow) {
							this.getExhibitionTimeListLately()
						}
						if (this.config.c3 && this.config.c3.isShow) {
							this.getListNews()
						}
						if (this.config.c4 && this.config.c4.isShow) {
							this.getHomeTopList()
						}
					}
				})

			},
			setHomeVisit() {
				setHomeVisit().then(res => {})
			},
			// 获取banner
			getListBanner() {
				getListBanner().then(res => {
					if (res.success) {
						this.bannerList = res.result

					}
				})
			},
			// 获取行业分类
			getIndustryCategoryList() {
				getIndustryCategoryList().then(res => {
					if (res.success) {
						this.selectIndustryCategoryList = res.result
					}
				})
			},
			// 获取市场区域
			getMarketProfileList() {
				getMarketProfileList().then(res => {
					if (res.success) {
						this.selectMarketProfileList = res.result
					}
				})
			},
			// 获取搜索的展会时间
			getExhibitionTimeList() {
				getExhibitionYearMonthList().then(res => {
					if (res.success) {
						this.selectExhibitionTimeList = res.result
					}
				})
			},
			// 搜索按钮
			handleSubmit() {
				this.$store.commit('sumbitFormExhibition', this.formExhibition)
				this.$router.push({
					path: '/exhibition'
				});
			},
			// 获取近期展会展会时间
			getExhibitionTimeListLately() {
				getExhibitionYearList().then(res => {
					if (res.success) {
						this.exhibitionTimeListLately = res.result
						let index = this.exhibitionTimeListLately.findIndex(item => {
							return item.curYear
						})
						this.globalDefaultYear = this.exhibitionTimeListLately[index].value;
						this.getMonthList()
					}
				})
			},
			// 获取年份下所有月份
			getMonthList() {
				// 取当前年在数组中所处的下标
				if (this.isDefaultYear) {
					this.currentYearIndex = this.exhibitionTimeListLately.findIndex(item => {
						return item.curYear
					})
				}
				this.isDefaultYear = false
				let params = {
					year: this.exhibitionTimeListLately[this.currentYearIndex].value,
				}
				getMonthList(params).then(res => {
					if (res.success) {
						this.monthLabel = res.result
						this.getHomeLatelyList()
					}
				})
			},

			// 更改月份
			handleChangeMonth(index) {
				this.currentMonthIndex = index
				this.getHomeLatelyList()
			},
			// 上一年
			handleYearPrev() {
				this.isDefaultMonth = true;
				this.currentYearIndex--
				this.getMonthList()
			},
			// 下一年
			handleYearNext() {
				this.isDefaultMonth = true;
				this.currentYearIndex++
				this.getMonthList()
			},
			// 获取某月展会信息
			getHomeLatelyList() {
				let curYear = this.exhibitionTimeListLately[this.currentYearIndex].value;
				// 取当前月在数组中所处的下标
				if (this.isDefaultMonth) {
					var flag = false;
					for (var i = 0; i < this.monthLabel.length; i++) {
						var item = this.monthLabel[i];
						if (this.globalDefaultYear != curYear) {
							flag = true;
						} else {
						//	if (item.curMonth == '1') {
								if (item.type == '1') {
									//当前月有数据
									this.currentMonthIndex = i;
									break;
								} else {
									//当前月没有数据，向后找最近的一个月
									flag = true;
								}
							//}
						}
						if (flag) {
							if (item.type == '1') {
								this.currentMonthIndex = i;
								break;
							}
						}
					}
				}
				this.isDefaultMonth = false
					
				let params = {
					yearMonth: this.exhibitionTimeListLately[this.currentYearIndex].value +
						'-' +
						this.monthLabel[this.currentMonthIndex].value,
				}
				getHomeLatelyList(params).then(res => {
					if (res.success) {
						this.currMonthExhbtition = res.result
						this.leftPic = this.currMonthExhbtition[0].coverImgUrl
					}
				})
			},
			// 切换左侧图片
			handleChangePic(index) {
				this.leftPic = this.currMonthExhbtition[index] && this.currMonthExhbtition[index].coverImgUrl
			},
			// 展会详情
			goDetailExhibition(e,type) {
				if(type==1){
					util.pageChange(this.$router, `/exhibitionDetail`, {
						id: e
					}, '_self');
				}else{
					util.pageChange(this.$router, `/exhibitionDetail`, {
						id: e
					}, '_blank');
				}
				

			},

			// 获取新闻列表
			getListNews() {
				getListNews().then(res => {
					if (res.success) {
						this.listNewsFirst = res.result[0]
						this.listNews = res.result.slice(1)
					}
				})
			},
			//新闻详情
			goDetail(e) {
				util.pageChange(this.$router, `/newsDetail`, {
					id: e
				}, '_blank');

			},

			// 获取推荐展会
			getHomeTopList() {
				getHomeTopList().then(res => {
					if (res.success) {
						this.listExhibitonTop = res.result

					}
				})
			},
			// 加载下载文件
			getFileUrlInHome() {
				getFileUrlInHome().then(res => {
					if (res.success) {
						this.downFile = res.result
					}
				})
			},

		},
	}
</script>

<style lang="scss" scoped>
	.banner {
		position: relative;
		max-height: 6rem;
		overflow: hidden;

		img {
			display: block;
			// max-width: 100%;
			// max-height: 100%;
		}
	}

	.bannerContent {
		padding: 1.2rem 0;
		width: 13rem;
		height: 100%;
		position: absolute;
		top: 0;
		left: 3.1rem;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;

		@media (max-width: 767px) {
			width: calc(100% - 1.5rem * 2);
			left: 1.5rem;
			padding: 0.1rem 0;
		}

		.title {
			color: #ffffff;
			font-size: 0.36rem;
		}

		.text {
			color: #ffffff;
			font-size: 0.24rem;

			@media (max-width: 767px) {
				font-size: 0.12rem;
				width: 100%;
				position: relative;

				&.first {
					top: -0.1rem;
				}
			}
		}

		.btnMain {
			width: 4.7rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 767px) {
				position: relative;
				top: -0.1rem;
			}

			.video {
				width: 2.7rem;
				height: 0.6rem;
				line-height: 0.6rem;
				text-align: center;
				font-size: 0.24rem;
				color: #ffffff;
				background-image: url(../../assets/bannerBtn.png);
				background-size: 100% 100%;
			}

			i {
				margin-left: 0.1rem;
			}
		}
	}

	.search {
		margin-top: 0.3rem;

		@media (max-width: 767px) {
			margin-top: 0.3rem;
		}

		form {
			.ivu-form-item {
				width: 3.2rem;
				margin-right: 0.8rem;

				@media (max-width: 767px) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 0.2rem;
				}
			}

			.ivu-form-item:last-child {
				width: 1rem;
				margin-right: 0rem;

				@media (max-width: 767px) {
					width: 50%;
					margin-left: 25%;
				}

				.ivu-btn-primary {
					background-color: #333333;
					border-color: #333333;
					border-radius: 0.1rem;
					width: 1rem;
					height: 0.5rem;
					font-size: .16rem;

					@media (max-width: 767px) {
						width: 100%;
						height: 0.5rem * 1.5;
						margin-top: 0.2rem;
						font-size: .36rem;
					}
				}
			}
		}
	}

	.homeTitle {
		color: #ff1932;
		font-size: 0.22rem;
		padding: 0.3rem 0 .2rem;

		&.pt {
			padding-top: 0.2rem;
		}

		@media (max-width:767px) {
			padding: 0.5rem 0 .3rem;
			font-size: 0.44rem;
		}

		&.lr {
			display: flex;
			justify-content: space-between;
			align-items: center;

		}
	}

	.exhibition {
		.yaerMain {
			.year {
				width: 2rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.yearLeft {
					width: 0.4rem;
					height: 0.4rem;
					background: #eeeeee;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					@media (max-width:767px) {
						width: 0.6rem;
						height: 0.6rem;

					}

					i {
						font-size: 0.3rem;

						@media (max-width:767px) {
							font-size: 0.6rem;

						}
					}

				}

				.yearCenter {
					font-size: 0.24rem;
					color: #333333;

					@media (max-width:767px) {
						font-size: 0.4rem;
						margin: 0 0.21rem;

					}
				}

			}

			.monthPoint {
				margin-top: 0.3rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;
				z-index: 2;

				div {
					width: 0.3rem;
					height: 0.3rem;
					background: #eeeeee;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: default;

					@media (max-width:767px) {
						width: 0.4rem;
						height: 0.4rem;

					}
				}

				// 当前月
				.month_curr {
					// background: #ff1932;
					background: #000000;
					cursor: pointer;
				}

				// 存在展会
				.month_have {
					background: #000000;
					cursor: pointer;
				}
			}

			.line {
				width: 100%;
				height: 0.04rem;
				background-color: #eeeeee;
				margin-top: -0.16rem;
				position: relative;
				z-index: 1;

				@media (max-width:767px) {
					margin-top: -0.2rem;

				}
			}

			.month {
				margin-top: 0.2rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				@media (max-width: 767px) {
					margin-top: 0.3rem;

				}

				div {
					width: 0.3rem;
					height: 0.3rem;
					background: #ffffff;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					font-size: 0.2rem;
					color: #333333;

					@media (max-width: 767px) {
						font-size: 0.4rem;

					}
				}
			}
		}

		.exhibitionMain {
			display: flex;
			justify-content: center;
			margin-top: 0.3rem;

			@media (max-width: 767px) {
				display: block;
				margin-top: 0.2rem;
				height: auto;
			}

			.exhibitionItemRight {
				width: 6.9rem;
				display: flex;
				align-self: start;
				flex-wrap: wrap;

				@media (max-width: 767px) {
					width: 100%;
				}

				.exhibitionItemRightMain {
					width: 100%;
					height: 1.2rem;
					display: flex;
					justify-content: center;
					align-items: center;

					@media (max-width: 767px) {
						height: 2rem;
						justify-content: flex-start;
					}

					.exhibitionItemDate {
						width: 1.4rem;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;

						.day,
						.year {
							font-size: 0.2rem;
							color: #333333;

							@media (max-width: 767px) {
								font-size: 0.4rem;

							}
						}

						.month {
							font-size: 0.24rem;
							color: #333333;

							@media (max-width: 767px) {
								font-size: 0.4rem;

							}
						}
					}

					.exhibitionItemContent {
						height: 100%;
						width: 5rem;
						padding-right: 0.3rem;

						@media (max-width: 767px) {
							width: 80%;
						}

						.title {
							padding-top: .1rem;
							font-size: 0.24rem;
							color: #333333;
							margin: 0.1rem 0;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;

							@media (max-width: 767px) {
								font-weight: bold;
								padding-top: .3rem;
								font-size: 0.4rem;
							}
						}

						.text {
							height: 1.3em;
							font-size: 0.2rem;
							color: #333333;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;

							// display: -webkit-box;
							// -webkit-line-clamp: 2;
							// -webkit-box-orient: vertical;
							@media (max-width: 767px) {
								height: .4rem;
							}
						}

						.iconMain {
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.iconItem {
								display: flex;
								align-items: center;
								width: 100%;

								img {
									width: 0.2rem;
									margin-right: 0.06rem;
								}

								p {
									font-size: 0.18rem;
									color: #333333;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									display: inline-block;

									@media (max-width: 767px) {
										font-size: 0.32rem;
									}
								}
							}
						}
					}
				}
			}

			.exhibitionItemRightMain:nth-child(odd) {
				background-color: #eeeeee;
			}

			.exhibitionItemLeft {
				width: 6.4rem;
				height: 3.6rem;
				flex: 0 0 6.4rem;
				overflow: hidden;

				@media (max-width:767px) {
					width: 100%;
					height: 100%;
				}

				img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}
		}

	}

	.moreBtn {
		&.down {
			width: 2rem;
			height: 0.5rem;
			line-height: 0.5rem;
			text-align: center;
			font-size: 0.16rem;
			color: #ffffff;
			background: #ff1932;
			border-radius: 0.1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #f00;

			@media (max-width:767px) {
				width: 3rem;
				height: 0.75rem;
				line-height: 0.75rem;
				font-size: 0.36rem;
			}

			img {
				margin-right: .06rem;
				width: .2rem;

				@media (max-width:767px) {
					width: .4rem;
				}
			}
		}
	}

	.moreExhibitionMain {
		margin-bottom: .3rem;
		text-align: right;

		.moreExhibition {
			color: #ff1932;
			font-size: .16rem;
			margin-top: .2rem;
			display: inline-block;

			@media (max-width: 767px) {
				font-size: 0.36rem;
			}

			@media (max-width:767px) {
				.ivu-icon {
					font-size: .2rem !important;
				}
			}

		}
	}

	.new {
		width: 100%;
		background: #eeeeee;

		.newMain {
			display: flex;
			justify-content: center;
			flex-direction: row-reverse;
			padding-bottom: 0.3rem;

			@media (max-width: 767px) {
				display: block;
			}

			.newMainLeft {
				width: 4.25rem;
				padding-right: 0.3rem;

				@media (max-width: 767px) {
					width: 100%;
					height: auto;
				}

				.newItem {
					cursor: pointer;

					.title {
						color: #ff1932;
						font-size: 0.2rem;
						// padding: 0 0 0.1rem;
						// white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						margin-bottom: 0.2rem;
						// line-height: 1.8;
						overflow: hidden;
						/*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;

						@media (max-width: 767px) {
							font-size: 0.38rem;
						}
					}

					.content {
						color: #333333;
						font-size: 0.2rem;
						margin-bottom: 0.2rem;
						line-height: 1.8;
						overflow: hidden;
						/*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}

				.more {
					margin-top: 0.2rem;
					width: 1.5rem;
					height: 0.5rem;
					text-align: center;
					line-height: 0.5rem;
					background-color: #333333;
					color: #ffffff;
					border-radius: 0.1rem;
					font-size: 0.2rem;

					@media (max-width: 767px) {
						width: 3rem;
						height: 0.8rem;
						line-height: 0.8rem;
						border-radius: 0.1rem;
						font-size: 0.38rem;
					}

					a {
						color: inherit;
					}
				}
			}

			.newMainRight {
				// height: 5rem;
				width: 8.65rem;
				display: flex;
				// justify-content: center;
				// align-items: center;
				background: #ffffff;
				padding: .3rem;

				@media (max-width: 767px) {
					width: 100%;
					height: auto;
					align-items: flex-start;
					margin-bottom: .3rem;
				}

				.newMainRightL {
					flex: 0 0 2.9rem;
					// height: 5rem;
					// width: 5rem;

					// padding: 0.5rem 0.2rem 0.1rem 0.5rem;

					@media (max-width: 767px) {
						width: 50%;
						flex: 0 0 60%;
					}

					.more {
						width: 2.4rem;
						height: 0.5rem;
						line-height: 0.5rem;
						text-align: center;
						font-size: 0.2rem;
						color: #ffffff;
						background-image: url(../../assets/bannerBtn.png);
						background-size: 100% 100%;
					}

					.title {
						width: 2.7rem;
						color: #ff1932;
						font-size: 0.2rem;
						margin-bottom: 0.1rem;
						// white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						overflow: hidden;
						/*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
						display: -webkit-box;
						-webkit-line-clamp: 5;
						-webkit-box-orient: vertical;
						margin-bottom: 0.3rem;
						text-align: justify;
						word-break: break-all;
					}

					.content {
						width: 100%;
						color: #333333;
						font-size: 0.18rem;
						line-height: 1.5;
						letter-spacing: 0.02rem;
						overflow: hidden;
						/*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
						display: -webkit-box;
						-webkit-line-clamp: 6;
						-webkit-box-orient: vertical;
						margin-bottom: 0.3rem;
						text-align: justify;
						word-break: break-all;
					}

					.detail {
						text-align: right;
						color: #ff1932;
						font-size: 0.16rem;
					}
				}

				.newMainRightR {
					width: 4.8rem;
					flex: 0 0 4.8rem;
					overflow: hidden;
					margin-right: .3rem;

					@media (max-width: 767px) {
						flex: 0 0 100%;
					}

					img {
						display: block;

						@media (max-width: 767px) {
							width: 30%;
							float: left;
							margin-right: .3rem;
							margin-bottom: .1rem;
						}
					}

					.title {
						color: #ff1932;
						font-size: 0.24rem;
						margin-bottom: 0.1rem;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;

						@media (max-width: 767px) {
							font-size: 0.38rem;
						}
					}

					.content {
						color: #333333;
						font-size: 0.18rem;
						letter-spacing: 0.02rem;
						margin-bottom: 0.3rem;

						@media (max-width: 767px) {
							font-size: 0.36rem;
						}
					}

					.detail {
						text-align: right;
						color: #ff1932;

						@media (max-width: 767px) {
							font-size: 0.32rem;
						}
					}
				}
			}
		}
	}

	.reExhibition {
		margin-bottom: .3rem;

		.partner {
			.picMain {
				cursor: pointer;
				// width: 3rem;

				@media (max-width: 767px) {
					width: auto;
				}

				.pic {
					border: 0.01rem solid #eeeeee;
					padding: 3%;
					height: 1.2rem;
					display: flex;
					justify-content: center;
					align-items: center;
					@media (max-width: 767px) {
						height: 1.6rem;
					}
					img {
						width: auto !important;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.name {
					color: #333333;
					font-size: 0.18rem;
					text-align: center;
					margin: 0.24rem 0 0.14rem 0;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					width: 90%;
					padding-left: 5%;
					@media (max-width: 767px) {
						font-size: 0.38rem;
					}
				}

				.time {
					color: #999999;
					font-size: 0.16rem;
					text-align: center;
					@media (max-width: 767px) {
						font-size: 0.36rem;
					}
				}
			}
		}

		.wCenterP {
			width: 15rem;
			margin: 0 auto;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.wCenterP .swiper-container {
			margin: 0 1rem;
			position: static;
		}

		.wCenterP {
			position: relative;
		}


	}
</style>
<style lang="scss" scoped>
	.canotClick {
		cursor: default !important;
	}

	.home {
		.search {

			::v-deep .ivu-select-selection,
			::v-deep .ivu-select-selected-value,
			::v-deep .ivu-select-placeholder {
				$lh: 0.5rem;
				$lh-wap: $lh * 1.5;
				height: $lh !important;
				line-height: $lh !important;

				@media (max-width: 767px) {
					height: $lh-wap !important;
					line-height: $lh-wap !important;
				}
			}

			.ivu-select-selection {
				background-color: #eeeeee;
				border-radius: 0;
				border: 1px solid #eeeeee;
			}
		}
	}
</style>
